<template>
  <div class="discussion-form">
    <p>{{ displayIndex }}.</p>
    
    <div class="inputs-container">
      <!-- Поле для discussion -->
      <input
          :readonly="isSwiping || isSwiped"
          @touchstart="startSwipe" 
          @touchmove="moveSwipe" 
          @touchend="endSwipe"
          :style="swipeStyle" 
          type="text"
          v-model="discussionText"  
          v-click-out-side="handleClickOutside"
          placeholder="Начните писать..."
      />
      
      <input
        type="text"
        v-model="responsibleText"
        placeholder="Ответственный"
      />

      <SelectorComponent
        v-for="selector in store.selectors"
        :key="selector.id"
        :selectorName="selector.name"
        :options="selector.options"
        @selectedOption="option => updateParameter(selector.id, option)"
      />

      <div @click="onDelete" class="delete-btn">
        Удалить
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';
import { useMeetingsStore } from '@/store/meetingStore';
import SelectorComponent from '@/components/meetings/SelectorComponent.vue';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const props = defineProps({
  id: Number,
  index: Number
});

const store = useMeetingsStore();

const swipeX = ref(0);
const isSwiped = ref(false);
const isSwiping = ref(false);
const deltaX = ref(0);

const startSwipe = (event) => {
    swipeX.value = event.touches[0].clientX; 
    isSwiping.value = true;
};

const moveSwipe = (event) => {
    const currentX = event.touches[0].clientX;
    const movement = currentX - swipeX.value;

    deltaX.value += movement;

    // Ограничиваем движение
    if (deltaX.value < -100) {
        deltaX.value = -100;
    } else if (deltaX.value > 0) {
        deltaX.value = 0;
    }

    swipeX.value = currentX;
};

const endSwipe = () => {
    isSwiping.value = false;

    // Если свайп влево больше порога (например, -50px), оставляем кнопку удаления
    if (deltaX.value < -50) {
        isSwiped.value = true;
        deltaX.value = -100;
        triggerHapticFeedback('medium', 'impact');
    } else if (deltaX.value >= -50 && deltaX.value < 0) {
        isSwiped.value = false;
        deltaX.value = 0;
        triggerHapticFeedback('light', 'impact');
    }
};

// Обработчик кликов вне элемента
const handleClickOutside = () => {
    if (!isSwiping.value) {
        deltaX.value = 0;
        isSwiped.value = false;
    }
};

// Стиль для управления положением элемента
const swipeStyle = computed(() => {
    return { transform: `translateX(${deltaX.value}px)` };
});

// Находим нужный элемент в store
const discussionItem = computed(() => {
  return store.form.discussions.find(d => d.id === props.id);
});

// discussion
const discussionText = computed({
  get() {
    return discussionItem.value?.discussion || '';
  },
  set(val) {
    if (discussionItem.value) {
      discussionItem.value.discussion = val;
    }
  }
});

// responsible
const responsibleText = computed({
  get() {
    return discussionItem.value?.responsible || '';
  },
  set(val) {
    if (discussionItem.value) {
      discussionItem.value.responsible = val;
    }
  }
});

const parametersMap = {
  'Только для правления': 'boardOnly',
  'Без ограничений': 'no_restrictions',
  'С ограничениями': 'restricted',
  'На почте': 'email',
  'Отсутствуют': 'none'
}

// конфиденциальность
const confidentiality = computed({
  get() {
    return discussionItem.value?.parameters.confidentiality || '';
  },
  set(val) {
    if (discussionItem.value) {
      discussionItem.value.parameters.confidentiality = parametersMap[val];
    }
  }
});

// материалы
const materials = computed({
  get() {
    return discussionItem.value?.parameters.materials || '';
  },
  set(val) {
    if (discussionItem.value) {
      discussionItem.value.parameters.materials = parametersMap[val];
    }
  }
});

// При выборе опции из селектора
const updateParameter = (selectorId, value) => {
  if (selectorId === 0) {
    confidentiality.value = value;
  } else if (selectorId === 1) {
    materials.value = value;
  }
};

// Отображаемый порядковый номер
const displayIndex = computed(() => props.index + 1);

// Удаление
const onDelete = () => {
  store.deleteDiscussion(props.id);
};
</script>

<style scoped>
.discussion-form {
    display: flex;
    flex-direction: row;
    gap: 9px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 18px;
    height: auto;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    position: relative;
    transition: transform 0.5s ease;
}

input {
    width: 100%;
    height: auto;
    border-radius: 6px;
    border: none;
    padding: 13px 18px;
    color: #2B323A;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 9px 16px -4px #00000014;
    background: white;
    box-sizing: border-box;
    z-index: 2;
}

input:focus {
    outline: none;
}

input::placeholder {
    color: #919191;
    font-size: 10px;
    font-weight: 400;
}

.delete-btn {
    position: absolute;
    top: 22px;
    right: 10px;
    transform: translateY(-50%);
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1;
}
</style>

