import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    /** @type {string} */
    firstName: '',
    /** @type {string} */
    lastName: '',
  }),
  actions: {
    setUser(user) {
      this.firstName = user.first_name;
      this.lastName = user.last_name;
    }
  },
});
