<template>
    <div class="start-screen">
    
        <img class="start-screen-img" :src="require('@/assets/startScreen.png')" alt="StartScreen" />

        <div class="start-screen-content">
            
            <div class="start-screen-title">
                <img :src="require('@/assets/solar_logo.svg')" alt="Logo" />
                <p>wise</p>
            </div>

            <p class="start-screen-text">Чтобы заседания были удобными</p>
        
            <div class="start-screen-button" @click="handleClickStart">
                Начнем?
            </div>

        </div>

    </div>
</template>

<script setup>
import { defineEmits } from 'vue';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const emit = defineEmits(['start']);

const handleClickStart = () => {
    
    triggerHapticFeedback("medium", "impact");
    emit('start');

};

</script>

<style scoped>

.start-screen {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

.start-screen-img {
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 38px 38px 0px 0px;
    z-index: 1;
}

.start-screen-content {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #F8F7FF 100%);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.start-screen-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 24px;
}

.start-screen-title img {
    width: 123px;
}

.start-screen-title p {
    font-weight: 275;
    font-size: 29px;
    color: #FE6E36;
}

.start-screen-text {
    margin-top: 50px;
    font-weight: 500;
    font-size: 38px;
    color: #2F394B;
    max-width: 330px;
    text-align: center;
}

.button-container {
    position: relative;
    width: 80%;
}

.start-screen-button {
    margin: 62px 52px 52px 52px;
    background: #FE6E36;
    color: white;
    border-radius: 15px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    padding: 18px 107px;
    box-shadow: 0 4px 10px rgba(254, 110, 54, 0.7);
}
</style>