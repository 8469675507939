<template>
  <div id="main-container">
    
    <LoadingComponent v-if="loading"/>

    <StartScreen v-else-if="showStartScreen"
      @start="showStartScreen = false"
    />

    <div v-else-if="authStore.auth" class="main-container">
      <HeaderComponent/>

      <div class="content">
        <router-view></router-view>
      </div>

      <NavigationPanel/>
    </div>

    <div v-else class="denied-container">
      <p>Доступ запрещен</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useAuthStore } from "./store/authStore";
import { useUserStore } from "./store/userStore";
import axios from './utils/axiosConfig';

import NavigationPanel from "./components/NavigationPanel/NavigationPanel.vue";
import LoadingComponent from "./components/LoadingComponent.vue";
import StartScreen from "./components/StartScreen.vue";
import HeaderComponent from "./components/HeaderComponent.vue";

const loading = ref(true);
const showStartScreen = ref(false);

const authStore = useAuthStore();
const userStore = useUserStore();

const preloadImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = resolve;
  });
};

onMounted(async () => {
  window.Telegram.WebApp.ready();

  const initData = window.Telegram.WebApp.initData;

  //предзагружаем картинки, чтобы они 
  //не грузились на глазах у пользователя
  await preloadImage(require('@/assets/startScreen.png'));
  await preloadImage(require('@/assets/solar_logo.svg'));

  if (initData) {

    axios.post('/auth/', { initData })
      .then((response) => {
        const { user, success } = response.data;

        if (success) {
          const { first_name = '', last_name = '' } = user;

          authStore.setAuth(true);
          userStore.setUser({
            first_name: first_name,
            last_name: last_name
          });

          //запускаем показ приветственного экрана
          showStartScreen.value = true;
        }
        //при негативном сценарии явно не ставим authStore.setAuth в false
        //так как значение по умолчанию изначально false       
      })
      .catch(() => {
        console.error('Ошибка при авторизации');
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    showStartScreen.value = true;
    authStore.setAuth(true);
    //останавливаем загрузку для отображения
    //экрана с запретом доступа
    loading.value = false;
  }
});
</script>

<style scoped>

.content {
  margin-top: 26px;
  padding: 0px 30px;
  height: calc(100% - 65px);
  overflow: hidden;
  display: flex;
  flex-direction: column
}

@font-face {
  font-family: 'TT Norms Regular';
  src: url('../public/fonts/TT Norms Pro Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms Black';
  src: url('../public/fonts/TT Norms Pro Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'TT Norms Regular';
}

.denied-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #ff0000;
  font-size: 24px;
  font-weight: bold;
}

#main-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>