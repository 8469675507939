<template>
    <VDatePicker
        v-model="date"
        borderless
        color="orange"
        :min-date="new Date()"
        mode="dateTime" is24hr
        :popover="{ visibility: 'click', placement: 'bottom-end' }"
        :rules="rules"
    >
        <template #default="{ togglePopover }">
            <div class="selected-date-container" @click="handleClick(togglePopover)">
            <img class="edit-icon" :src="require('@/assets/meetings/edit-icon.png')" alt="EditIcon" />
            <p 
                class="selected-date"    
            >
                {{ selectedDate }} {{ selectedTime }}
            </p>
            </div>
        </template>
    </VDatePicker>
</template>

<script setup>
import { ref, defineEmits, watch, onMounted, computed } from 'vue';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const emit = defineEmits(['getSelectedDate']);
const date = ref();
const selectedDate = ref();
const selectedTime = ref();
const rules = ref({
  hours: { min: 10, max: 19 },
  minutes: { interval: 5 },
});

onMounted(() => {
    selectedDate.value = formattedDate.value;
    date.value = nextThursday.value;
    emit('getSelectedDate', {'date': selectedDate.value, 'time': selectedTime.value});
});

const getNextThursday = () => {
  const today = new Date();
  const daysToThursday = (4 - today.getDay() + 7) % 7; 
  today.setDate(today.getDate() + daysToThursday);
  today.setHours(16, 0, 0, 0);
  return today;
};

const nextThursday = computed(() => getNextThursday());

const formattedDate = computed(() => {
    const date = nextThursday.value;
    return formatDate(date);
});

const handleClick = (togglePopover) => {
    triggerHapticFeedback('light', 'impact');
    togglePopover();
};

const formatDate = (date) => {
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
};

//при выборе даты в календаре, обновляем selectedDate
watch(date, (newDate) => {
    triggerHapticFeedback('light', 'impact');
    selectedDate.value = formatDate(newDate);
    selectedTime.value = newDate.getHours() + ':' + String(newDate.getMinutes()).padStart(2, '0');
    date.value = newDate;
    emit('getSelectedDate', {'date': selectedDate.value, 'time': selectedTime.value});
});

</script>

<style scoped>
.selected-date {
    font-size: 12px;
    color: #FE6E36;
    font-weight: 700;
}

.selected-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.edit-icon {
    width: 15px;
}
</style>