<template>
    <div class="header">
        <div class="name">
            <p v-if="lastName" class="last-name">{{ lastName }}</p>
            <p class="first-name">{{ firstName }}</p>
        </div>
        
        <img class="logo" :src="require('@/assets/solar_logo.svg')" alt="Logo" />
    </div>
</template>

<script setup>
import { useUserStore } from '@/store/userStore';
import { computed } from 'vue';

const userStore = useUserStore();
const firstName = computed(() => userStore.firstName);
const lastName = computed(() => userStore.lastName);

</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 0px 30px;
}

.name {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.first-name, .last-name {
    font-weight: 400;
    font-size: 14px;
    color: #FE6E36;
}

.logo {
    width: 77px;
}

</style>