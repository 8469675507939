<template>

    <div class="back-button-container">
        <div class="back-button" @click="goBack">
            <img class="back-icon" :src="require('@/assets/back-icon.png')" alt="BackIcon" />
            <p>Назад</p>
        </div>
    </div>

</template>

<script setup>
import { useRouter } from 'vue-router';
import { defineProps } from 'vue';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const props = defineProps({
    routeName: String
});

const router = useRouter();

const goBack = () => {
    triggerHapticFeedback("light", "impact");
    router.push({ name: props.routeName });
}

</script>

<style scoped>
.back-button-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.back-icon {
    width: 11px;
}

p {
    font-size: 14px;
    color: #2B323A;
    font-weight: 400;
}

</style>