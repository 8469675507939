import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    /**
     * Статус авторизации.
     * Значение по умолчанию — false.
     * Если пользователь не авторизован, это состояние используется.
     */
    auth: false,
  }),
  actions: {
    setAuth(status) {
      this.auth = status;
    },
  },
});
