<template>
  <div class="task-card">
    <div class="task-header">Заседание от 12.11.2024</div>
    <div class="task-title">{{ title }}</div>
    <div class="task-footer">
      <div class="due-date">{{ date }}</div>
      <div class="status">{{ status }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskCard",
  props: {
    title: String,
    date: String,
    status: String,
  },
};
</script>

<style scoped>
.task-card {
  background: #ffffff;
  box-shadow: 0px 9px 16px -4px rgba(0, 0, 0, 0.08);
  border-radius: 17px;
  padding: 10px;
  margin-bottom: 15px;
}

.task-header {
  color: #a1a1a1;
  font-size: 12px;
}

.task-title {
  color: #2b323a;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
}

.task-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.due-date {
  background: #fef3e7;
  color: #2b323a;
  border-radius: 17px;
  padding: 5px 10px;
}

.status {
  color: #a1a1a1;
}
</style>