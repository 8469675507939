<template>
    <div class="navigation-panel">
        <NavigationButton
            v-for="(button, index) in buttons"
            :key="button.label"
            :label="button.label"
            :icon-active-url="button.iconActiveUrl"
            :icon-inactive-url="button.iconInactiveUrl"
            :selected="selectedIndex === index" 
            :class-name="button.className"
            @click="handleButtonClick(index)" 
        />
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import NavigationButton from "./NavigationButton.vue";
import { useRouter, useRoute } from 'vue-router';
import { triggerHapticFeedback } from '../../utils/hapticFeedback';

const baseIconPath = "navigationPanel/";
const activeIconPath = `${baseIconPath}activeIcons/`;
const inactiveIconPath = `${baseIconPath}inactiveIcons/`;

const router = useRouter();
const route = useRoute();

const buttons = [
    {
        label: "Мероприятия",
        iconActiveUrl: `${activeIconPath}people-icon-active.png`,
        iconInactiveUrl: `${inactiveIconPath}people-icon-inactive.png`,
        className: "people",
        pathName: "meetings"
    },
    {
        label: "Задачи",
        iconActiveUrl: `${activeIconPath}tasks-icon-active.png`,
        iconInactiveUrl: `${inactiveIconPath}tasks-icon-inactive.png`,
        className: "tasks",
        pathName: "tasks"
    },
    {
        label: "Уведомления",
        iconActiveUrl: `${activeIconPath}notifications-icon-active.png`,
        iconInactiveUrl: `${inactiveIconPath}notifications-icon-inactive.png`,
        className: "notifications",
        pathName: "notifications"
    },
];

const selectedIndex = ref(0);

onMounted(() => {
    router.push({ name: buttons[0].pathName });
})

const handleButtonClick = (index) => {
    selectedIndex.value = index;
    triggerHapticFeedback("soft", "impact");
    router.push({ name: buttons[index].pathName });
};

// Следим за изменением route.name
watch(
  () => route.name,       // что наблюдать
  (newRouteName) => {     // что делать при изменении
    const newIndex = buttons.findIndex(
      (b) => b.pathName === newRouteName
    );
    if (newIndex >= 0) {
      selectedIndex.value = newIndex;
    }
  },
  { immediate: true } // Запустить сразу при инициализации
);

</script>

<style scoped>
.navigation-panel {
    width: 100%;
    height: 72px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    gap: 42px;
}


</style>