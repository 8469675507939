<template>
    <div class="navigation-button" @click="handleClick" :class="{ 'selected': props.selected }">
        <div  class="indicator" :class="props.selected ? 'active' : ''"></div>
        <img 
            class="icon" 
            :src="require(`@/assets/${props.selected ? props.iconActiveUrl : props.iconInactiveUrl}`)" 
            :class="props.className"
            alt="Icon" 
        />
        <p class="label" :class="{ 'active': props.selected }">{{ props.label }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    label: String,
    iconActiveUrl: String,
    iconInactiveUrl: String,
    selected: Boolean,
    className: String
});

const emit = defineEmits(['click']);

const handleClick = () => {
    emit('click');
};

</script>

<style scoped>
.navigation-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    height: 100%;
}

.label {
    font-size: 12px;
    color: #2B323A;
    font-weight: 400;
    margin-bottom: 20px;
}

.label.active {
    font-weight: 500;
    color: #FE6E36;
}

.icon.people{
    width: 20px;
}

.icon.tasks{
    width: 14px;
}

.icon.notifications{
    width: 16.5px;
}

.indicator {
    width: 62px;
    height: 6px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: auto;
}

.indicator.active {
    background: #FE6E36;
}
</style>
