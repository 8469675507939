import MeetingsView from '@/views/MeetingsView.vue'
import TasksView from '@/views/TasksView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import AddMeetingView from '@/views/AddMeetingView.vue'

import { createRouter, createWebHistory } from 'vue-router'

const routes = [

    {
        path: '/meetings',
        name: 'meetings',
        component: MeetingsView,
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: TasksView
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsView
    },
    {
        path: '/add-meeting',
        name: 'add-meeting',
        component: AddMeetingView
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;