<template>
    <div v-click-out-side="closeSelector" class="selector-container" @click="openSelector">
        <div class="selector">
            <p v-if="!selectedOption || opened"> {{ props.selectorName }} </p>
            <p v-else> {{ selectedOption }} </p>
            <img class="selector-icon" :src="require('@/assets/arrow-icon.png')" alt="SelectorIcon" />
        </div>

        <div v-if="opened" class="options">
            <div v-for="option in props.options" class="option" @click.stop="selectOption(option)" :key="option">
                <div class="circle" :class="{ 'active': selectedOption === option }"></div>
                <p>{{ option }}</p>
            </div>
        </div>
        
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { clickOutSide as vClickOutSide } from "@mahdikhashan/vue3-click-outside";
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const selectedOption = ref();
const opened = ref(false);

const emit = defineEmits(['selectedOption']);

const props = defineProps({
    selectorName: String,
    options: Array
})

const openSelector = () => {
    if (opened.value) return;
    opened.value = true;
    triggerHapticFeedback('light', 'impact');
}

const closeSelector = () => {
    opened.value = false;
}

//при выборе параметра закрываем селектор
//и передаем в родителя значение
const selectOption = (option) => {
    triggerHapticFeedback('light', 'impact');
    opened.value = false;
    selectedOption.value = option;
    emit('selectedOption', option);
}
</script>

<style scoped>
.selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 13px 13px 13px 18px;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 9px 16px -4px rgba(0, 0, 0, 0.08);
    gap: 6px;
    width: 100%;
    height: auto;
}

.selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #2B323A;
    font-weight: 400;
    font-size: 10px;
}

.selector-icon {
    width: 9px;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    width: 100%;
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 9px;
    cursor: pointer;
    color: #2B323A;
    font-size: 12px;
    font-weight: 500;
}

.circle {
    width: 5.4px;
    height: 5.4px;
    border-radius: 50%;
    border: 0.6px solid #919191;
}

.active {
    background-color: #919191;
}


</style>