<template>
    <div>
      <h2>Уведомления</h2>
      <!-- Здесь будет функциональность уведомлений -->
      <p>Список уведомлений появится здесь.FFFFF</p>
    </div>
</template>
  
<script>
  export default {
    name: "NotificationsApp",
  };
</script>
  