<template>
    <div class="add-meeting-button" @click="handleClick">
        <img class="add-icon" :src="require('@/assets/meetings/add-icon.png')" alt="AddIcon" />
        <p>Добавить</p>
    </div>
</template>

<script setup>
import { triggerHapticFeedback } from '@/utils/hapticFeedback';
import { defineEmits } from 'vue';

const emit = defineEmits(['click']);

const handleClick = () => {
    triggerHapticFeedback("medium", "impact");
    emit('click');
}

</script>

<style scoped>

.add-meeting-button {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    justify-content: center;
    width: 100%;
    justify-content: flex-start;
}

.add-icon {
    width: 13px;
}

.add-meeting-button p {
    font-size: 16px;
    font-weight: 400;
    color: #2B323A;
}

</style>
