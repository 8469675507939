<template>
    <GoBackButton routeName="meetings" />

    <div class="header">
        <p class="header-title">Заседание правления</p>
        <CalendarComponent @getSelectedDate="store.receiveSelectedDate" />
        
    </div>
    <p class="content-title">Повестка</p>

    <div class="discussions">

        <div v-for="(item, index) in store.form.discussions" :key="item.id">

          <DiscussionFormComponent
            :id="item.id"
            :index="index"
          />

        </div>
    
        <AddButtonComponent @click="store.addDiscussion" />
      </div>
    
      <div class="save-button" @click="handleSave">
        <p>Сохранить</p>
      </div>

</template>
  
<script setup>
import GoBackButton from '@/components/GoBackButton.vue';
import CalendarComponent from '@/components/addMeetings/CalendarComponent.vue';
import DiscussionFormComponent from '@/components/addMeetings/DiscussionFormComponent.vue';
import AddButtonComponent from '@/components/addMeetings/AddButtonComponent.vue';
import { useMeetingsStore } from '@/store/meetingStore';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';
import router from '@/router';

const store = useMeetingsStore();

const handleSave = async () => {
    triggerHapticFeedback('medium', 'impact');
    const result = await store.saveMeeting();
    console.log('Результат сервера:', result);

    if (result) {
        triggerHapticFeedback('success');
        router.push({ name: 'meetings' });
    }       
}
</script>
  

<style scoped>

* {
    box-sizing: border-box;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 11px;
}

.header-title {
    font-size: 16px;
    color: #2B323A;
    font-weight: 500;
}

.parameters {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-start;
    margin-bottom: 21px;
}

.discussions {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    margin-bottom: 10px;
    padding-bottom: 73px;
}

.save-button {
    margin-bottom: 19px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00B69B;
    border-radius: 12px;
    font-size: 14px;
    color: white;
    box-shadow: 0px 14px 23px 0px #1C252C14;
    flex-shrink: 0;
    width: 185px;
    margin-left: auto;
    margin-right: auto;
}
</style>