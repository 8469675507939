<template>

    <div class="closest-meeting">
        
        <div class="closest-meeting-header">
            <p class="closest-meeting-title">Предстоящее заседание</p>
            <img class="arrow-icon" :src="require('@/assets/meetings/arrow-icon-white.png')" alt="ArrowIcon" />
        </div>
        
        <div class="closest-meeting-content">
            <p class="closest-meeting-date"> {{ props.date }} </p>
            <p class="meeting-confidetiality">{{ props.confidetiality }}</p>
        </div>

    </div>

</template>

<script setup>

import { defineProps } from 'vue';

const props = defineProps({
    date: String,
    confidetiality: String
});

</script>

<style scoped>

.closest-meeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #FE8235;
    box-sizing: border-box;
    padding: 20px 23px;
    gap: 18px;
    width: 100%;
    margin-bottom: 19px;
}

.closest-meeting-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.closest-meeting-title {
    font-size: 18px;
    font-weight: 800;
    color: #FFFFFF;
}

.arrow-icon {
    width: 18px;
}

.closest-meeting-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.closest-meeting-date {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
}

.meeting-confidetiality {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

</style>