<template>
    <div class="tabs-container">
        <div class="tabs-switch">
            <div 
                v-for="(tab, index) in props.tabs" 
                :key="index" 
                class="tab"
                :class="{ 'selected': selectedIndex === index }" 
                @click="handleTabClick(index)"
            >
                <p>{{ tab.name }}</p>
                <div class="indicator" :class="{ 'active': selectedIndex === index }"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const props = defineProps({
    tabs: {
        type: Array,
        required: true
    }
});

const selectedIndex = ref(0);

const emit = defineEmits(['tabSelected']);

//устанавливаем текущий индекс и передаем его родителю
const handleTabClick = (index) => {
    triggerHapticFeedback("light", "impact");
    selectedIndex.value = index;
    emit('tabSelected', index);
};

</script>

<style scoped>

.tabs-container {
    height: 28px;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 2px solid #fff;
}

.tabs-switch {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: -2px;
    padding-left: 5px;
    gap: 20px;
    justify-content: flex-start;
}

.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #919191;
    gap: 8px;
    cursor: pointer;
}

.selected {
    color: #2B323A;
}

.selected .indicator {
    display: flex;
    width: calc(100% + 10px);
    height: 3px;
    background-color: #FE6E36;
    border-radius: 10px 10px 0px 0px;
}

</style>