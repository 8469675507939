<template>

    <div class="meetings">

        <ClosestMeeting
            v-if="nearestMeeting"
            :confidetiality="nearestMeeting.confidentiality"
            :date="nearestMeeting.date"
        />

        <TabsSwitchComponent 
            :tabs="meetingsTabs" 
            @tab-selected="handleTabSelected"
        />

        <AddButtonComponent v-if="currentIndex === 0" @click="addMeeting" />

        <div class="content">

            <div v-if="currentIndex === 0">
                
                <div v-for="meeting in meetings" :key="meeting.id">

                        <div class="meeting">
                            
                            <div class="meeting-header">
                                <p class="meeting-date">Заседание от {{ meeting.date }} </p>
                                <img class="arrow-icon" :src="require('@/assets/meetings/arrow-right-icon.png')" alt="ArrowIcon" />
                            </div>

                            <div class="meeting-info">
                            
                                <div class="meeting-tasks">
                                    <img class="task-icon" :src="require('@/assets/meetings/task-icon.png')" alt="TaskIcon" />
                                    <p>{{ meeting.tasks }}</p>
                                </div>

                                <div class="active-tasks">
                                    <img class="clock-icon" :src="require('@/assets/meetings/clock-icon.png')" alt="ActiveIcon" />
                                    <p>{{ meeting.active_tasks }}</p>
                                </div>
                            
                            </div>
                        
                        </div>

                </div>

            </div>
        
        </div>
        
    </div>

</template>

<script setup>
import TabsSwitchComponent from '@/components/meetings/TabsSwitchComponent.vue';
import AddButtonComponent from '@/components/addMeetings/AddButtonComponent.vue';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import ClosestMeeting from '@/components/meetings/ClosestMeeting.vue';
import axios from '@/utils/axiosConfig';

const router = useRouter();

const meetings = ref([]);
const nearestMeeting = ref(null);
const currentIndex = ref(0);

const meetingsTabs = ref([
    { name: "Будущие заседания" },
    { name: "Прошедшие заседания" }
]);

const addMeeting = () => {
    router.push({ name: "add-meeting" });
}

const handleTabSelected = (index) => {
    currentIndex.value = index;
}

function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0'); // День с ведущим нулём
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц с ведущим нулём
    const year = date.getFullYear(); // Год
    return `${day}.${month}.${year}`;
}


onMounted(() => {
    axios.get('/meetings').then((response) => {
        // Сохраняем полный список встреч
        meetings.value = response.data.map(meeting => ({
            ...meeting,
            date: formatDate(meeting.date) // Форматируем дату сразу для всех элементов
        }));

        console.log(meetings.value);

        const currentDate = new Date();

        // Находим ближайшее заседание
        const nearest = response.data
            .filter(meeting => new Date(meeting.date) >= currentDate) // Даты >= текущей
            .sort((a, b) => new Date(a.date) - new Date(b.date))[0]; // Сортируем и берём первое

        if (nearest) {
            // Создаём независимую копию ближайшего заседания с отформатированной датой
            nearestMeeting.value = {
                ...nearest,
                date: formatDate(nearest.date)
            };
        } else {
            nearestMeeting.value = null; // Если нет ближайших заседаний
        }
    }).catch((error) => {
        console.error('Ошибка при получении заседаний:', error);
    });
});
</script>

<style scoped>

.meetings {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.content {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    margin-bottom: 10px;
    padding-bottom: 73px;
}
</style>