import { defineStore } from 'pinia';
import axios from '@/utils/axiosConfig';

export const useMeetingsStore = defineStore('meetings', {
  state: () => ({
    form: {
      date: '',
      time: '',
      discussions: []
    },
    selectors: [
      {
        id: 0,
        name: "конфиденциальность",
        options: [
          "Только для правления",
          "Без ограничений",
          "С ограничениями"
        ]
      },
      {
        id: 1,
        name: "материалы",
        options: [
          "На почте",
          "Отсутствуют"
        ]
      }
    ]
  }),

  actions: {

    receiveSelectedDate({ date, time }) {
      this.form.date = date; 
      this.form.time = time;
    },

    addDiscussion() {
      const newId = Date.now();
      this.form.discussions.push({
        id: newId,
        discussion: '',
        responsible: null,
        parameters: {
          confidentiality: '',
          materials: ''
        }
      });
    },

    updateParameters(id, parameters) {
      console.log(parameters);
      const parametersMap = {
        'Только для правления': 'boardOnly',
        'Без ограничений': 'no_restrictions',
        'С ограничениями': 'restricted',
        'На почте': 'email',
        'Отсутствуют': 'none'
      }

      parameters.confidentiality = parametersMap[parameters.confidentiality];
      parameters.materials = parametersMap[parameters.materials];

      // Обновление параметров (конфиденциальность, материалы)
      const item = this.form.discussions.find(d => d.id === id);
      if (item && parameters) {
        item.parameters = {
          ...item.parameters,
          ...parameters
        };
      }
    },

    deleteDiscussion(id) {
      const index = this.form.discussions.findIndex(d => d.id === id);
      if (index !== -1) {
        this.form.discussions.splice(index, 1);
      }
    },

    async saveMeeting() {
      try {
        const payload = {
          date: this.combineDateTime(this.form.date, this.form.time),
          agenda_items: this.form.discussions.map(d => ({
            description: d.discussion,
            responsible: d.responsible,
            confidentiality: d.parameters.confidentiality,
            materials: d.parameters.materials
          }))
        };

        const response = await axios.post('meetings/', payload);

        this.resetForm();

        // Данные результата
        console.log('Успешно сохранено:', response.data);

        return response.data;
      } catch (error) {
        console.error('Ошибка:', error);
      }
    },

    resetForm() {
      this.form = {
        date: '',
        time: '',
        discussions: []
      };
    },

    combineDateTime(date, time) {
      const [day, month, year] = date.split('.');
      const isoDate = `${year}-${month}-${day}`;
      return `${isoDate}T${time}:00`;
    }
  }
});
